* {
  box-sizing: border-box;
}

strong {
  font-weight: 500;
}

html {
  font-size: 16px;
}

body {
  text-rendering: optimizeLegibility;
  font-family: 'Inter UI', 'Arial', sans-serif;
  background: #43afe4;
  color: white;
  font-size: 20px;
  line-height: 1.6;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

input[type='email']:valid + div {
  display: block !important;
}

#main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.header {
  font-size: 2rem;
  padding: 1rem;
  height: 4rem;
  line-height: 1;
}

.beta {
  color: rgba(255, 255, 255, 0.5);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

h2 {
  font-size: 1.65em;
  margin-bottom: 0.5em;
}

p {
  margin-bottom: 1.15em;
}

a {
  color: white;
}

.btn {
  background: #000;
  color: #fff;
  display: block;
  max-width: 300px;
  border: none;
  margin: 0;
  border-radius: 0;
  text-decoration: none;
  font-size: 1em;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}
.btn:hover,
.btn:focus {
  background-color: #333;
}

input,
textarea {
  padding: 1rem;
  border: none;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.form-row {
  margin-bottom: 3rem;
}

.form-control {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  border: 0;
  font-size: 1.25rem;
  color: #000;
  border-radius: 0;
}
.form-control:hover {
  background-color: rgba(255, 255, 255, 0.75);
}
.form-control:focus {
  background-color: white;
  outline: 0;
}

label {
  cursor: pointer;
}

.content {
  min-height: calc(100vh - 4rem);
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 4rem 1rem;
}
.content .content-container {
  max-width: 48rem;
  margin: 0 auto;
}

.hint {
  font-size: 0.7em;
}
.hint.checkbox {
  display: inline-flex;
  align-items: center;
}
.hint svg {
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
}
.hint svg .check {
  opacity: 0;
}
.hint input {
  position: absolute;
  left: -10000px;
  opacity: 0;
}
.hint input:checked ~ svg .check {
  opacity: 1;
}

#thank-you {
  /* Hide until form has been submitted */
  display: none;
}

.select-question {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.select-question .radio-select {
  display: none;
}

.ie-select {
  flex: 1;
  margin-left: 1rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.ie-select select::-ms-expand {
  display: none;
}
.ie-select select {
  height: 3em;
  border-radius: 0;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: 1em;
  padding: 1rem;
  position: relative;
  z-index: 1;
  padding-right: 2em;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 0;
  width: 100%;
  border-bottom: 3px solid #fff;
}
.ie-select select option {
  color: #000;
}
.ie-select::after {
  content: '';
  display: block;
  height: 0.5rem;
  width: 0.5rem;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.3em;
  z-index: 0;
  transform-origin: 50% 60%;
  transition: transform 0.15s ease-out;
}

@supports (-webkit-appearance: none) {
  body:not(.is-mobile) .ie-select {
    display: none;
  }
  body:not(.is-mobile) .select-question input {
    position: absolute;
    left: -10000px;
    opacity: 0;
  }
  body:not(.is-mobile) .select-question .radio-select {
    flex: 1;
    margin-left: 1rem;
    height: 3em;
    border-bottom: 3px solid #fff;
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  body:not(.is-mobile) .select-question .options {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.1);
    height: 3em;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  body:not(.is-mobile) .select-question .options label {
    display: block;
    min-height: 3em;
    padding: 1em 0.5em;
    padding-right: 2rem;
    line-height: 1;
    order: 2;
    background-color: #2da6e1;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  body:not(.is-mobile) .select-question .options label::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
  }
  body:not(.is-mobile) .select-question .options label:hover::before {
    opacity: 0.2;
  }
  body:not(.is-mobile) .select-question .options .select-placeholder {
    order: 1;
    opacity: 0.65;
    background: none;
  }
  body:not(.is-mobile)
    .select-question
    .options
    input[type='radio']:focus
    + label::before {
    opacity: 0.2;
  }
  body:not(.is-mobile)
    .select-question
    .options
    input[type='radio']:checked
    + label {
    order: 0;
    background: none;
  }
  body:not(.is-mobile)
    .select-question
    .options
    input[type='radio']:checked
    + label::before {
    display: none;
  }
  body:not(.is-mobile)
    .select-question
    .options
    input[type='radio']:checked
    ~ .select-placeholder {
    display: none;
  }
  body:not(.is-mobile) .select-question input.select-label + label {
    display: block;
    height: 3em;
    top: 0;
    left: 0;
    z-index: 10;
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
  }
  body:not(.is-mobile) .select-question input.select-label + label::after {
    content: '';
    display: block;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.3em;
    z-index: 20;
    transform-origin: 50% 60%;
    transition: transform 0.15s ease-out;
  }
  body:not(.is-mobile) .select-question input.select-label + label:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body:not(.is-mobile) .select-question input.select-label:focus + label {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body:not(.is-mobile) .select-question input.select-label:checked + label {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body:not(.is-mobile)
    .select-question
    input.select-label:checked
    + label::after {
    transform: rotate(135deg);
  }
  body:not(.is-mobile) .select-question input.select-label:checked ~ .options {
    overflow: visible;
  }
}
